/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../components/seo"

import Page from "../layouts/page"

import { Link } from "gatsby"

const LegalPage = () => {
  return <Page>
    <div>
      <div className="contain page-body">
        <ul class="uk-breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li><span>Articles</span></li>
        </ul>

        <h1>Articles</h1>

        <ul>
          <li><Link to="/articles/what-is-an-asn">What is an ASN?</Link></li>
        </ul>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Legal"
    path="/legal"
  />
)

export default LegalPage
